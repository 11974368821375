import React, { Fragment } from "react";

import { Meta } from "./imprint/meta";
import { Content } from "./imprint/content";

export const Template = () => (
  <Fragment>
    <Meta />
    <Content />
  </Fragment>
);

export default Template;
