import React from "react";

import { MetaTags, getProperty } from "@lachevaliniere/shared";

import { useStaticImprint } from "hooks";

export const Meta = () => {
  const staticImprint = useStaticImprint();

  const title = getProperty(staticImprint, "data.metaTitle");
  const description = getProperty(staticImprint, "data.metaDescription");

  return <MetaTags title={title} description={description} />;
};
